<template>
  <div class="category-menu sticky-navbar">
    <ul class="nav nav-pills">
      <div class="menu-item-wrapper">
        <li>
          <router-link :to="{ path: '/clothing', query: {color: color, reload: viewKey + 1 } }">Clothing</router-link>
        </li>
        
        <div class="submenu">
          <li class="women">
            <router-link :to="{ path: '/clothing', query: { type:'women',color: color, reload: viewKey + 1 } }">Women</router-link>

            <div class="submenu1">
           <li>
             <router-link :to="{ path: '/clothing', query: { type:'women',subcategory:'jackets',color: color, reload: viewKey + 1 } }">Jackets</router-link>
</li>
<li>
  <router-link :to="{ path: '/clothing', query: {type:'women',subcategory:'coats', color: color, reload: viewKey + 1 } }">Coats</router-link>
</li>

            <li> <router-link :to="{ path: '/clothing', query: {type:'women',subcategory:'trenchcoats', color: color, reload: viewKey + 1 } }">Trench Coats</router-link></li>
            <li><router-link :to="{ path: '/clothing', query: {type:'women',subcategory:'blazers', color: color, reload: viewKey + 1 } }">Blazers</router-link></li>
            <li><router-link :to="{ path: '/clothing', query: {type:'women',subcategory:'waistCoatsgilets', color: color, reload: viewKey + 1 } }">WaistCoats|Gilets</router-link></li>
            <li>  <router-link :to="{ path: '/clothing', query: {type:'women',subcategory:'dresses', color: color, reload: viewKey + 1 } }">Dresses</router-link></li>
            <li><router-link :to="{ path: '/clothing', query: {type:'women',subcategory:'jumpsuits', color: color, reload: viewKey + 1 } }">JumpSuits</router-link></li>
            <!-- Tops|BodySuits -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'tops', color: color, reload: viewKey + 1 }
          }"
        >
          Tops|BodySuits
        </router-link>
      </li>

      <!-- Shirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'shirts', color: color, reload: viewKey + 1 }
          }"
        >
          Shirts
        </router-link>
      </li>

      <!-- T-shirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 't-shirts', color: color, reload: viewKey + 1 }
          }"
        >
          T-shirts
        </router-link>
      </li>

      <!-- Sweatshirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'sweatshirts', color: color, reload: viewKey + 1 }
          }"
        >
          Sweatshirts
        </router-link>
      </li>

      <!-- Knitwear -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'knitwear', color: color, reload: viewKey + 1 }
          }"
        >
          Knitwear
        </router-link>
      </li>

      <!-- Trousers -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'trousers', color: color, reload: viewKey + 1 }
          }"
        >
          Trousers
        </router-link>
      </li>

      <!-- Jeans -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'jeans', color: color, reload: viewKey + 1 }
          }"
        >
          Jeans
        </router-link>
      </li>

      <!-- Shorts|Skorts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'shorts-skorts', color: color, reload: viewKey + 1 }
          }"
        >
          Shorts|Skorts
        </router-link>
      </li>

      <!-- Skirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'skirts', color: color, reload: viewKey + 1 }
          }"
        >
          Skirts
        </router-link>
      </li>

      <!-- Shoes -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'shoes', color: color, reload: viewKey + 1 }
          }"
        >
          Shoes
        </router-link>
      </li>

      <!-- Lingerie|Pyjamas -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'lingerie-pyjamas', color: color, reload: viewKey + 1 }
          }"
        >
          Lingerie|Pyjamas
        </router-link>
      </li>

      <!-- Suits -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'women', subcategory: 'suits', color: color, reload: viewKey + 1 }
          }"
        >
          Suits
        </router-link>
      </li>
            </div>
          </li>
          <li class="men">
          <router-link :to="{ path: '/clothing', query: { type:'men',color: color, reload: viewKey + 1 } }">Men</router-link>
            <div class="submenu2">
           <!-- Jackets -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'jackets', color: color, reload: viewKey + 1 }
          }"
        >
          Jackets
        </router-link>
      </li>

      <!-- Coats -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'coats', color: color, reload: viewKey + 1 }
          }"
        >
          Coats
        </router-link>
      </li>

      <!-- Trench Coats -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'trenchcoats', color: color, reload: viewKey + 1 }
          }"
        >
          Trench Coats
        </router-link>
      </li>

      <!-- Hoodies -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'hoodies', color: color, reload: viewKey + 1 }
          }"
        >
          Hoodies
        </router-link>
      </li>

      <!-- SweatShirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'sweatshirts', color: color, reload: viewKey + 1 }
          }"
        >
          SweatShirts
        </router-link>
      </li>

      <!-- Tracksuits -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'tracksuits', color: color, reload: viewKey + 1 }
          }"
        >
          Tracksuits
        </router-link>
      </li>

      <!-- Trousers -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'trousers', color: color, reload: viewKey + 1 }
          }"
        >
          Trousers
        </router-link>
      </li>

      <!-- Jeans -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'jeans', color: color, reload: viewKey + 1 }
          }"
        >
          Jeans
        </router-link>
      </li>

      <!-- Shirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'shirts', color: color, reload: viewKey + 1 }
          }"
        >
          Shirts
        </router-link>
      </li>

      <!-- T-shirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 't-shirts', color: color, reload: viewKey + 1 }
          }"
        >
          T-shirts
        </router-link>
      </li>

      <!-- Sweaters -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'sweaters', color: color, reload: viewKey + 1 }
          }"
        >
          Sweaters
        </router-link>
      </li>

      <!-- Cardigans -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'cardigans', color: color, reload: viewKey + 1 }
          }"
        >
          Cardigans
        </router-link>
      </li>

      <!-- Overshirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'overshirts', color: color, reload: viewKey + 1 }
          }"
        >
          Overshirts
        </router-link>
      </li>

      <!-- Suits -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'suits', color: color, reload: viewKey + 1 }
          }"
        >
          Suits
        </router-link>
      </li>

      <!-- Blazers -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'blazers', color: color, reload: viewKey + 1 }
          }"
        >
          Blazers
        </router-link>
      </li>

      <!-- Polo Shirts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'polo-shirts', color: color, reload: viewKey + 1 }
          }"
        >
          Polo Shirts
        </router-link>
      </li>

      <!-- Shorts -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'shorts', color: color, reload: viewKey + 1 }
          }"
        >
          Shorts
        </router-link>
      </li>

      <!-- Shoes -->
      <li>
        <router-link
          :to="{
            path: '/clothing',
            query: { type: 'men', subcategory: 'shoes', color: color, reload: viewKey + 1 }
          }"
        >
          Shoes
        </router-link>
      </li>
            </div>
          </li>
        </div>
      </div>
      <div class="menu-item-wrapper">
        <li>
          <router-link :to="{ path: '/homeliving', query: {color: color, reload: viewKey + 1 } }">Home Living</router-link>
        </li>
        <div class="submenu">
          <li class="fur"><router-link :to="{ path: '/homeliving', query: {type:'furniture',color: color, reload: viewKey + 1 } }">Furniture</router-link>
              <div class="submenu3">
              <li><router-link :to="{ path: '/homeliving', query: {type:'furniture',subcategory:'chairsarmchairs',color: color, reload: viewKey + 1 } }">Chairs|Armchairs</router-link></li>
               <!-- Stools|Benches -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'furniture', subcategory: 'stoolsbenches', color: color, reload: viewKey + 1 }
          }"
        >
          Stools|Benches
        </router-link>
      </li>

      <!-- Sofas -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'furniture', subcategory: 'sofas', color: color, reload: viewKey + 1 }
          }"
        >
          Sofas
        </router-link>
      </li>

      <!-- Tables -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'furniture', subcategory: 'tables', color: color, reload: viewKey + 1 }
          }"
        >
          Tables
        </router-link>
      </li>

      <!-- Shelves -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'furniture', subcategory: 'shelves', color: color, reload: viewKey + 1 }
          }"
        >
          Shelves
        </router-link>
      </li>

      <!-- Beds Furniture -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'furniture', subcategory: 'bedsfurniture', color: color, reload: viewKey + 1 }
          }"
        >
          Beds Furniture
        </router-link>
      </li>
              
              </div>
          </li>
          <li class="deco"><router-link :to="{ path: '/homeliving', query: {type:'decoration',color: color, reload: viewKey + 1 } }">Decoration</router-link>
          <div class="submenu4">
             <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'rugs', color: color, reload: viewKey + 1 }
          }"
        >
          Rugs
        </router-link>
      </li>

      <!-- Mirrors -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'mirrors', color: color, reload: viewKey + 1 }
          }"
        >
          Mirrors
        </router-link>
      </li>

      <!-- Lighting -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'lighting', color: color, reload: viewKey + 1 }
          }"
        >
          Lighting
        </router-link>
      </li>

      <!-- Vases|Flowerpots -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'vasesflowerpots', color: color, reload: viewKey + 1 }
          }"
        >
          Vases|Flowerpots
        </router-link>
      </li>

      <!-- Candles|CandleHolders -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'candlescandleholders', color: color, reload: viewKey + 1 }
          }"
        >
          Candles|CandleHolders
        </router-link>
      </li>

      <!-- Baskets -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'baskets', color: color, reload: viewKey + 1 }
          }"
        >
          Baskets
        </router-link>
      </li>

      <!-- Frames|Boxes -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'framesboxes', color: color, reload: viewKey + 1 }
          }"
        >
          Frames|Boxes
        </router-link>
      </li>

      <!-- Accessories -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'accessories', color: color, reload: viewKey + 1 }
          }"
        >
          Accessories
        </router-link>
      </li>

      <!-- Curtains -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'curtains', color: color, reload: viewKey + 1 }
          }"
        >
          Curtains
        </router-link>
      </li>

      <!-- Cushions|Filling -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'cushionsfilling', color: color, reload: viewKey + 1 }
          }"
        >
          Cushions|Filling
        </router-link>
      </li>

      <!-- Blankets -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'decoration', subcategory: 'blankets', color: color, reload: viewKey + 1 }
          }"
        >
          Blankets
        </router-link>
      </li>
            </div>
          </li>
          <li class="bed"><router-link :to="{ path: '/homeliving', query: {type:'bedroom',color: color, reload: viewKey + 1 } }">Bedroom</router-link>
          <div class="submenu5">
               <!-- Bed Linen -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bedroom', subcategory: 'bedlinen', color: color, reload: viewKey + 1 }
          }"
        >
          Bed Linen
        </router-link>
      </li>

      <!-- Quilt Covers -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bedroom', subcategory: 'quiltcovers', color: color, reload: viewKey + 1 }
          }"
        >
          Quilt Covers
        </router-link>
      </li>

      <!-- BedSpreads|Covers -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bedroom', subcategory: 'bedspreadscovers', color: color, reload: viewKey + 1 }
          }"
        >
          BedSpreads|Covers
        </router-link>
      </li>

      <!-- Fillings|Protectors -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bedroom', subcategory: 'fillingsprotectors', color: color, reload: viewKey + 1 }
          }"
        >
          Fillings|Protectors
        </router-link>
      </li>

      <!-- Blankets|Cushions -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bedroom', subcategory: 'blanketscushions', color: color, reload: viewKey + 1 }
          }"
        >
          Blankets|Cushions
        </router-link>
      </li>

      <!-- Dressing Room -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bedroom', subcategory: 'dressingroom', color: color, reload: viewKey + 1 }
          }"
        >
          Dressing Room
        </router-link>
      </li>
          </div>
          </li>
          <li class="din"><router-link :to="{ path: '/homeliving', query: {type:'diningroom',color: color, reload: viewKey + 1 } }">Dining room</router-link>
          <div class="submenu6">
               <!-- TableCoths -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'diningroom', subcategory: 'tablecloths', color: color, reload: viewKey + 1 }
          }"
        >
          TableCoths
        </router-link>
      </li>

      <!-- TableWare -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'diningroom', subcategory: 'tableware', color: color, reload: viewKey + 1 }
          }"
        >
          TableWare
        </router-link>
      </li>

      <!-- GlassWare -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'diningroom', subcategory: 'glassware', color: color, reload: viewKey + 1 }
          }"
        >
          GlassWare
        </router-link>
      </li>

      <!-- Cultery -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'diningroom', subcategory: 'cultery', color: color, reload: viewKey + 1 }
          }"
        >
          Cultery
        </router-link>
      </li>

      <!-- Table Accessories -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'diningroom', subcategory: 'tableaccessories', color: color, reload: viewKey + 1 }
          }"
        >
          Table Accessories
        </router-link>
      </li>

      <!-- Trays -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'diningroom', subcategory: 'trays', color: color, reload: viewKey + 1 }
          }"
        >
          Trays
        </router-link>
      </li>

      <!-- Coffee -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'diningroom', subcategory: 'coffee', color: color, reload: viewKey + 1 }
          }"
        >
          Coffee
        </router-link>
      </li>
          </div>
          </li>
          <li class="kitchen"><router-link :to="{ path: '/homeliving', query: {type:'kitchen',color: color, reload: viewKey + 1 } }">Kitchen</router-link>
          <div class="submenu7">
              <!-- Kitchen Storage -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'kitchen', subcategory: 'kitchenstorage', color: color, reload: viewKey + 1 }
          }"
        >
          Kitchen Storage
        </router-link>
      </li>

      <!-- Kitchen Accessories -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'kitchen', subcategory: 'kitchenaccessories', color: color, reload: viewKey + 1 }
          }"
        >
          Kitchen Accessories
        </router-link>
      </li>

      <!-- Kitchen Textiles -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'kitchen', subcategory: 'kitchentextiles', color: color, reload: viewKey + 1 }
          }"
        >
          Kitchen Textiles
        </router-link>
      </li>

      <!-- HouseHold Appliancies -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'kitchen', subcategory: 'householdappliances', color: color, reload: viewKey + 1 }
          }"
        >
          HouseHold Appliancies
        </router-link>
      </li>
          </div>
          </li>
          <li class="bath"><router-link :to="{ path: '/homeliving', query: {type:'bathroom',color: color, reload: viewKey + 1 } }">Bathroom</router-link>
           <div class="submenu8">
               <!-- Basic Towels -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bathroom', subcategory: 'basictowels', color: color, reload: viewKey + 1 }
          }"
        >
          Basic Towels
        </router-link>
      </li>

      <!-- Towels -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bathroom', subcategory: 'towels', color: color, reload: viewKey + 1 }
          }"
        >
          Towels
        </router-link>
      </li>

      <!-- BathRobes -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bathroom', subcategory: 'bathrobes', color: color, reload: viewKey + 1 }
          }"
        >
          BathRobes
        </router-link>
      </li>

      <!-- Bathroom Sets -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bathroom', subcategory: 'bathroomsets', color: color, reload: viewKey + 1 }
          }"
        >
          Bathroom Sets
        </router-link>
      </li>

      <!-- Bathroom Baskets -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bathroom', subcategory: 'bathroombaskets', color: color, reload: viewKey + 1 }
          }"
        >
          Bathroom Baskets
        </router-link>
      </li>

      <!-- Bath Mats -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bathroom', subcategory: 'bathmats', color: color, reload: viewKey + 1 }
          }"
        >
          Bath Mats
        </router-link>
      </li>

      <!-- Curtains -->
      <li>
        <router-link
          :to="{
            path: '/homeliving',
            query: { type: 'bathroom', subcategory: 'curtains', color: color, reload: viewKey + 1 }
          }"
        >
          Curtains
        </router-link>
      </li>
          </div>
          </li>
          <li class="clean"><router-link :to="{ path: '/homeliving', query: {type:'laundrycleaning',color: color, reload: viewKey + 1 } }">Laundry|Cleaning</router-link>
          </li>
        </div>
      </div>
      <li class="menu-item">
        <router-link :to="{ path: '/makeup', query: {color: color, reload: viewKey + 1 } }">Makeup</router-link>
        <div class="submenu10">
              <li><router-link :to="{ path: '/makeup', query: {type:'eyes',color: color, reload: viewKey + 1 } }">Eyes</router-link></li>
              <li><router-link :to="{ path: '/makeup', query: {type:'lips',color: color, reload: viewKey + 1 } }">Lips</router-link></li>
              <li><router-link :to="{ path: '/makeup', query: {type:'face',color: color, reload: viewKey + 1 } }">Face</router-link></li>
              <li><router-link :to="{ path: '/makeup', query: {type:'shadefinder',color: color, reload: viewKey + 1 } }">Shade Finder </router-link></li>
              <li><router-link :to="{ path: '/makeup', query: {type:'nailpolish',color: color, reload: viewKey + 1 } }">Nail Polish </router-link></li>
              <li><router-link :to="{ path: '/makeup', query: {type:'accessories',color: color, reload: viewKey + 1 } }">Accessories </router-link></li>
          </div>
      </li>
      <li class="menu-item">
        <router-link :to="{ path: '/accessories', query: {color: color, reload: viewKey + 1 } }">Accessories</router-link>
        <div class="submenu10">
              <li><router-link :to="{ path: '/accessories', query: {type:'belts',color: color, reload: viewKey + 1 } }">Belts</router-link></li>
              <li><router-link :to="{ path: '/accessories', query: {type:'jewlery',color: color, reload: viewKey + 1 } }">Jewlery</router-link></li>
              <li><router-link :to="{ path: '/accessories', query: {type:'hats',color: color, reload: viewKey + 1 } }">Hats</router-link></li>
              <li><router-link :to="{ path: '/accessories', query: {type:'scarves',color: color, reload: viewKey + 1 } }">Scarves</router-link></li>
              <li><router-link :to="{ path: '/accessories', query: {type:'sockstights',color: color, reload: viewKey + 1 } }">Socks|Tights</router-link></li>
          </div>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  props: {
    color: String // Définissez le type de la prop selon vos besoins
  },
  data() {
    return {

      viewKey: this.$route.query.reload || 0, // Initialize viewKey with the initial reload query parameter
    };
  },
  created() {
    console.log(this.color);
    this.viewKey = parseInt(this.$route.query.reload) || 0;
  },
};
</script>
<style lang="scss" scoped>
.category-menu {
  position: relative;
  top: 0;
  background-color: #000000;
  padding: 10px;
  text-align: center;
  height: 70px;
  z-index: 100; /* Adjust the z-index as needed */
  max-width: 1200px;
  margin: 0 auto; /* Center the menu on larger screens */
}
.category-menu.sticky-navbar {
  position: relative;
  top: 0; /* Adjust this value according to your design */

  z-index: 1000; /* Adjust the z-index value as needed */
  /* Other styling for your navigation bar */
}

.category-menu ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.menu-item-wrapper {
  position: relative;
  display: inline-block;
  margin-right: 30px;
}

.menu-item-wrapper li {
  display: block;
  margin-left: 20px;
  //margin-top: 3px;
}

.menu-item-wrapper a {
  text-decoration: none;
  color: #ffff;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
 
}

.menu-item-wrapper a:hover {
  color: #ff0000;
}

.menu-item-wrapper .submenu {
  position: absolute;
  //margin-left: 10px;
  background-color: #000;
  border: 1px solid #4d0404;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  //z-index: 5;
  display: none;
  border-radius: 8px;
  width: 200px;
  align-items: center;
}

.menu-item-wrapper:hover .submenu {
  display: block;
}

/* Target the submenus within .submenu1 and .submenu2 */
.women:hover .submenu1,
.men:hover .submenu2,
.fur:hover .submenu3,
.deco:hover .submenu4,
.bed:hover .submenu5,
.din:hover .submenu6,
.kitchen:hover .submenu7,
.bath:hover .submenu8

 {
  display: block; 
  position: absolute;
 /* top: 0;
  left: 100%;*/
  background-color: #000000;
  border: 1px solid #4d0404;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.submenu1,
.submenu2,
.submenu3,
.submenu4,
.submenu5,
.submenu6,
.submenu7,
.submenu8

 {
  //position: absolute;
 

  display: none;
  
}

.menu-item {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  background-color: #000;
}

.menu-item a {
    text-decoration: none;
  color: #ffff;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
  transition: color 0.3s;
}

.menu-item a:hover {
  color: #ff0000;
}

.submenu li {
  margin-right: 20px;
} 
.menu-item .submenu10 {
    position: absolute;
  margin-left: -50px;
  background-color: #000;
  border: 1px solid #4d0404;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: none;
width: 200px;
border-radius: 8px;
}
.menu-item:hover .submenu10 {
  display: block;
}
.submenu::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #fff;
  transition: color 0.3s;
}

.menu-item:hover .submenu::before {
  color: #ff0000;
}

.submenu-item {
  margin-right: 20px;
}
@media screen and (max-width: 900px) {
    .category-menu {
      display:none;
    }
  }


 
</style>

