<template>
  <div class="home-view">
   
    <div class="home-content">
         <div class="container">
          <div class="logo">
            <img src="@/assets/sansf.png" alt="Logo" />
          </div>
       </div>
      <div class="title-container">
        <h1 class="title">Choose your favorite color !</h1>
      </div>
      <div class="color-gallery">
         <!-- Gallery item 1 -->
        <button class="color-button" @click="selectColor('red')">
          <img src="@/assets/red.jpg" alt="Red" class="color-image" />
          <p class="color-name">Red</p>
        </button>


        <!-- Gallery item 2 -->
        <button class="color-button" @click="selectColor('green')">
          <img src="@/assets/green.jpg" alt="Green" class="color-image" />
          <p class="color-name">Green</p>
        </button>

      
          <!-- Gallery item 3 -->
        <button class="color-button" @click="selectColor('yellow')">
          <img src="@/assets/jaune.jpg" alt="Green" class="color-image" />
          <p class="color-name">Yellow</p>
        </button>


         <!-- Gallery item 5 -->
       <button class="color-button" @click="selectColor('saumon')">
          <img src="@/assets/saumon.jpg" alt="Saumon" class="color-image" />
          <p class="color-name">Saumon</p>
        </button>

        <!-- Gallery item 6 -->
        <button class="color-button" @click="selectColor('orange')">
          <img src="@/assets/orange.jpg" alt="orange" class="color-image" />
          <p class="color-name">Orange</p>
        </button>

        <!-- Gallery item 7 -->
       <button class="color-button" @click="selectColor('brown')">
          <img src="@/assets/marron.jpg" alt="Marron" class="color-image" />
          <p class="color-name">Marron</p>
        </button>
          <!-- Gallery item 8 -->
        <button class="color-button" @click="selectColor('cream')">
          <img src="@/assets/bzigz.jpg" alt="Beige" class="color-image" />
          <p class="color-name">Creamy</p>
        </button>

         <!-- Gallery item 9 -->
        <button class="color-button" @click="selectColor('lilac')">
          <img src="@/assets/violet.jpg" alt="Green" class="color-image" />
          <p class="color-name">Violet</p>
        </button>

        <!-- Gallery item 2 -->
        <button class="color-button" @click="selectColor('pink')">
          <img src="@/assets/rose.jpg" alt="Green" class="color-image" />
          <p class="color-name">Rose</p>
        </button>

        <!-- Gallery item 3 -->
        <button class="color-button" @click="selectColor('lightblue')">
          <img src="@/assets/lbleu.jpg" alt="Green" class="color-image" />
          <p class="color-name">Light Blue</p>
        </button>
          <!-- Gallery item 4 -->
       <button class="color-button" @click="selectColor('blue')">
          <img src="@/assets/bleu.jpg" alt="Blue" class="color-image" />
          <p class="color-name">Blue</p>
        </button>

      

        <!-- Gallery item 2 -->
        <button class="color-button" @click="selectColor('black')">
          <img src="@/assets/noir.jpg" alt="Green" class="color-image" />
          <p class="color-name">Black</p>
        </button>

        <!-- Gallery item 3 -->
        <button class="color-button" @click="selectColor('gray')">
          <img src="@/assets/grey.jpg" alt="Green" class="color-image" />
          <p class="color-name">Grey</p>
        </button>
          <!-- Gallery item 4 -->
        <button class="color-button" @click="selectColor('white')">
          <img src="@/assets/blanc.jpg" alt="White" class="color-image" />
          <p class="color-name">White</p>
        </button>
      </div>
    </div>
    <Footer /> <!-- Include the Footer component here -->
  </div>
</template>

<script>
//import Vue from 'vue';
import Footer from '@/components/Footer.vue';



export default {
  name: "HomeView",
  components: {
   
    Footer,
  },
  methods:{
selectColor(selectedColor) {
      // Update the path and query parameter with the selected color
      this.$router.push({ path: `/${selectedColor}/allproducts`, query: { colorName: selectedColor , color: selectedColor } });
    },
  
  },
  

};
</script>

<style lang="scss" scoped>
/* Add your CSS styles for HomeView here */
.home-view {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.logo img {
  max-width: 100%; /* Ajustez la largeur maximale de l'image pour qu'elle soit responsive */
  height: auto;
}

.home-content {
  background-color: black;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px; /* Add some padding to prevent overlap with footer */
}
.container {
  display: flex;
  flex-direction: column; /* Changez la direction de la flexbox pour s'adapter à la taille de l'écran */
  align-items: center;
  padding: 10px 1rem; /* Adjust vertical padding */
  max-width: 100%; /* Ensure it doesn't exceed the viewport width */
  margin: 0 auto; /* Center the content horizontally */
}
.title-container {
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.color-gallery {
  display: grid;
 grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-gap: 0px; /* Adjust the gap as needed */
}
.color-button {
  border: none;
  background: none;
  cursor: pointer;
  text-align: center;
}

.color-button:hover {
  text-decoration: underline;
  font-family: 'Times New Roman', Times, serif;
}

.color-item {
  text-align: center;
}

.color-image {
  width: 200px;
  height:  200px;
  object-fit: cover;
  border-radius: 8px;
}

.color-name {
  margin-top: 10px;
  color: white;
}
.title {
  font-size: 36px;
  font-weight: bold;
  font-family: 'Playfair Display', serif;
  color: white;
  margin: 0;
  animation: fadeInUp 1s ease;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (max-width: 900px) {
  .logo img {
    max-width: 250px; /* Ajustez la largeur maximale pour le logo sur les écrans mobiles */
  }

  .color-gallery {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Ajustez les colonnes pour les écrans mobiles */
  }
  .color-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

  .color-button {
    flex: 1 1 calc(50% - 20px);
    margin: 10px;
    box-sizing: border-box;
  }
}
</style>
