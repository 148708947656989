<template>
  <footer class="footer">
    <div class="footer-top">
      <div class="footer-content">
        <router-link to="/aboutus" class="footer-content-item">About us</router-link>
        <div>|</div>
        <div class="footer-content-item">Contact Us</div>
       
      </div>
    </div>
    <div class="footer-bottom">
      <div class="copyright">
        Made with <span class="heart">♥</span> by WD. &copy; 2023 ColorMeChic. All Rights Reserved.
      </div>
      <div class="socials">
        <a href="https://www.facebook.com/" target="_blank" class="social-icon">
          <font-awesome-icon :icon="['fab', 'fa-facebook']" size="2x" />
        </a>
        <a href="https://www.instagram.com/" target="_blank" class="social-icon">
          <font-awesome-icon :icon="['fab', 'fa-instagram']" size="2x" />
        </a>
        <a href="https://www.tiktok.com/" target="_blank" class="social-icon">
          <font-awesome-icon :icon="['fab', 'fa-']" size="2x" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  props: {},
};
</script>

<style lang="scss" scoped>
.footer {
  padding: 20px;
  background-color: black;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer-top {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

.footer-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer-content-item {
  text-align: center;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  color: white;
}

.footer-content-item:hover {
  color: gray;
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
}

.footer-divider {
  border-bottom: 1px solid white;
  width: 100%;
  margin-bottom: 10px;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.copyright {
  text-align: center;
  font-size: 12px;
  margin-bottom: 10px;
}

.social-icon {
  color: inherit;
  transition: all 0.1s ease;
}

.social-icon:hover {
  filter: drop-shadow(0 2px 10px var(--button-color));
}

.heart {
  color: red;
  font-size: 24px;
}
</style>