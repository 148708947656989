<template>
    <div id="app">
     
        <font-awesome-icon :icon="['fas', 'fa-bars']" size="2x" style="margin-left: 20px; color: white;" class="dd" @click="toggleMenu"/>
      
      <transition name="fade">
        <div class="menu-popup" v-if="showMenu">
          <div class="menu-header">
            <button class="close-btn" @click="toggleMenu">&times;</button>
          </div>
          <ul class="menu" :class="{ active: showMenu }">
            <li v-for="(menuItem, index) in menuItems" :key="index">
              <div class="menu-item">
                <template v-if="menuItem.route">
                  <router-link :to="{ path: menuItem.route.path, query: menuItem.route.query }">
            <span class="item-label" @click.stop="closeMenu">{{ menuItem.label }}</span>
                    </router-link>
                </template>
                <template v-else>
                  <span class="item-label" @click.stop="toggleSubmenu(index)" style="color: white; margin-left: 12px;">{{ menuItem.label }}</span>
                  <span class="item-icon" @click.stop="toggleSubmenu(index)">
                   
                    <font-awesome-icon :icon="['fas', 'fa-chevron-down']" />
                  </span>
                </template>
              </div>
              <ul class="submenu" :class="{ active: submenuActive === index }">
                <li v-for="(subMenuItem, subIndex) in menuItem.submenu" :key="subIndex">
                  <div class="submenu-item">
                    <template>
                      <router-link :to="{ path: subMenuItem.route.path, query: subMenuItem.route.query }">
    <span class="item-label" @click.stop="closeMenu">{{ subMenuItem.label }}</span>
  </router-link>
                    </template>
                    <template>
                      <span class="item-label" @click.stop="toggleSubsubmenu(index, subIndex)"></span>
                      <span class="item-icon" @click.stop="toggleSubsubmenu(index, subIndex)">
                        <font-awesome-icon :icon="['fas', 'fa-chevron-down']" style="margin-left: 10px;" />
                      </span>
                    </template>
                  </div>
                  <ul class="subsubmenu" :class="{ active: subsubmenuActive === index + '-' + subIndex }">
                    <li v-for="(subsubMenuItem, subsubIndex) in subMenuItem.subsubmenu" :key="subsubIndex">
                      <router-link :to="{ path: subsubMenuItem.route.path, query: subsubMenuItem.route.query }">
    <span class="item-label" @click.stop="closeMenu">{{ subsubMenuItem.label }}</span>
  </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        viewKey: this.$route.query.reload || 0, 
        showMenu: false,
        submenuActive: null,
        subsubmenuActive: null,
        menuItems: [
          {
            label: 'Clothing',
            route: null,
            submenu: [
         
               {
                label: 'Women',
                route: { path: '/clothing', query: { type:'women',color: this.$route.query.color, reload: this.viewKey + 1 } },
                subsubmenu: [
                {
                  label: 'Dresses',
                  route: { path: '/clothing', query: {type:'women',subcategory:'dresses', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
  
                { label: 'Jackets',
                   route: { path: '/clothing', query: { type:'women',subcategory:'jackets',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Coats',
                  route:{ path: '/clothing', query: {type:'women',subcategory:'coats', color: this.$route.query.color, reload: this.viewKey + 1 } },
                },
  
                { label: 'trenchcoats',
                   route: { path: '/clothing', query: { type:'women',subcategory:'trenchcoats',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Blazers',
                  route: { path: '/clothing', query: {type:'women',subcategory:'blazers', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
  
                { label: 'Waist coats | Gilets',
                   route: { path: '/clothing', query: { type:'women',subcategory:'waistCoatsgilets',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Jumpsuits',
                  route: { path: '/clothing', query: {type:'women',subcategory:'jumpsuits', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
  
                { label: 'Tops|Btops|Bodysuits',
                   route: { path: '/clothing', query: { type:'women',subcategory:'tops',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Shirts',
                   route: { path: '/clothing', query: { type:'women',subcategory:'shirts',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'T-shirts',
                   route: { path: '/clothing', query: { type:'women',subcategory:'t-shirts',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Sweatshirts',
                   route: { path: '/clothing', query: { type:'women',subcategory:'sweatshirts',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Knitwear',
                   route: { path: '/clothing', query: { type:'women',subcategory:'knitwear',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Trousers',
                   route: { path: '/clothing', query: { type:'women',subcategory:'trousers',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Jeans',
                  route: { path: '/clothing', query: {type:'women',subcategory:'jeans', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
  
                { label: 'Shorts|Skorts',
                   route: { path: '/clothing', query: { type:'women',subcategory:'shorts-skorts',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Skirts',
                   route: { path: '/clothing', query: { type:'women',subcategory:'skirts',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Shoes',
                   route: { path: '/clothing', query: { type:'women',subcategory:'shoes',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Lingerie|pyjamas',
                   route: { path: '/clothing', query: { type:'women',subcategory:'lingerie-pyjamas',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Suits',
                   route: { path: '/clothing', query: { type:'women',subcategory:'suits',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
             
                ],
              },
              {
                label: 'Men',
                route: '/about/team',
                subsubmenu: [
                { label: 'Jackets',
                   route: { path: '/clothing', query: { type:'men',subcategory:'jackets',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Coats',
                  route:{ path: '/clothing', query: {type:'men',subcategory:'coats', color: this.$route.query.color, reload: this.viewKey + 1 } },
                },
  
                { label: 'Trenchcoats',
                   route: { path: '/clothing', query: { type:'men',subcategory:'trenchcoats',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Blazers',
                  route: { path: '/clothing', query: {type:'men',subcategory:'blazers', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
                { label: 'Hoodies',
                   route: { path: '/clothing', query: { type:'men',subcategory:'hoodies',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Sweatshirts',
                  route:{ path: '/clothing', query: {type:'men',subcategory:'sweatshirts', color: this.$route.query.color, reload: this.viewKey + 1 } },
                },
  
                { label: 'Tracksuits',
                   route: { path: '/clothing', query: { type:'men',subcategory:'tracksuits',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Trousers',
                  route: { path: '/clothing', query: {type:'men',subcategory:'trousers', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
                {
                  label: 'Jeans',
                  route: { path: '/clothing', query: {type:'men',subcategory:'jeans', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
                { label: 'Shirts',
                   route: { path: '/clothing', query: { type:'men',subcategory:'shirts',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'T-shirts',
                   route: { path: '/clothing', query: { type:'men',subcategory:'t-shirts',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Sweaters',
                   route: { path: '/clothing', query: { type:'men',subcategory:'sweaters',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Cardigans',
                  route: { path: '/clothing', query: {type:'men',subcategory:'cardigans', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
                {
                  label: 'Overshirts',
                  route: { path: '/clothing', query: {type:'men',subcategory:'overshirts', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
                { label: 'Suits',
                   route: { path: '/clothing', query: { type:'men',subcategory:'suits',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                { label: 'Blazers',
                   route: { path: '/clothing', query: { type:'men',subcategory:'blazers',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
                {
                  label: 'Polo-shirts',
                  route: { path: '/clothing', query: {type:'men',subcategory:'polo-shirts', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
                {
                  label: 'Shorts',
                  route: { path: '/clothing', query: {type:'men',subcategory:'shorts', color:  this.$route.query.color, reload: this.viewKey + 1 } },
                },
                { label: 'Shoes',
                   route: { path: '/clothing', query: { type:'men',subcategory:'shoes',color: this.$route.query.color, reload: this.viewKey + 1 } }
                },
               
                ],
              },
            ],
          },
          {
            label: 'Home Living',
            route: null,
            submenu: [
              {
                label: 'furniture',
                route: { path: '/homeliving', query: {type:'furniture',color:  this.$route.query.color, reload: this.viewKey + 1 } },
                subsubmenu: [
                  { label: 'Chairs|Armchairs', route: { path: '/homeliving', query: {type:'furniture',subcategory:'chairsarmchairs',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: ' Stools|Benches', route: { path: '/homeliving', query: {type:'furniture',subcategory:'stoolsbenches',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Sofas', route: { path: '/homeliving', query: {type:'furniture',subcategory:'sofas',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: ' Tables', route: { path: '/homeliving', query: {type:'furniture',subcategory:'tables',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Shelves', route: { path: '/homeliving', query: {type:'furniture',subcategory:'shelves',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: ' Beds Furniture', route: { path: '/homeliving', query: {type:'furniture',subcategory:'bedsfurniture',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                 
                ],
              },
              {
                label: 'decoration',
                route:  { path: '/homeliving', query: {type:'decoration',color:  this.$route.query.color, reload: this.viewKey + 1 } } ,
                subsubmenu: [
                  { label: 'Rugs', route: { path: '/homeliving', query: {type:'decoration',subcategory:'rugs',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Mirrors', route: { path: '/homeliving', query: {type:'decoration',subcategory:'mirrors',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Lighting', route: { path: '/homeliving', query: {type:'decoration',subcategory:'lighting',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Vases|Flowerpots', route: { path: '/homeliving', query: {type:'decoration',subcategory:'vasesflowerpots',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Candles|CandleHolders', route: { path: '/homeliving', query: {type:'decoration',subcategory:'candlescandleholders',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Baskets', route: { path: '/homeliving', query: {type:'decoration',subcategory:'baskets',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Framesboxes', route: { path: '/homeliving', query: {type:'decoration',subcategory:'framesboxes',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Accessories', route: { path: '/homeliving', query: {type:'decoration',subcategory:'accessories',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Curtains', route: { path: '/homeliving', query: {type:'decoration',subcategory:'curtains',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Cushions|Filling', route: { path: '/homeliving', query: {type:'decoration',subcategory:'cushionsfilling',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Blankets', route: { path: '/homeliving', query: {type:'decoration',subcategory:'blankets',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  
                ],
              },
              {
                label: 'bedroom',
                route:{ path: '/homeliving', query: {type:'bedroom',color:  this.$route.query.color, reload: this.viewKey + 1 } },
                subsubmenu: [
                  { label: 'Bed Linen', route: { path: '/homeliving', query: {type:'bedroom',subcategory:'bedlinen',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Quilt Covers', route: { path: '/homeliving', query: {type:'bedroom',subcategory:'quiltcovers',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'BedSpreads|Covers', route: { path: '/homeliving', query: {type:'bedroom',subcategory:'bedspreadscovers',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Fillings|Protectors', route: { path: '/homeliving', query: {type:'bedroom',subcategory:'fillingsprotectors',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Blankets|Cushions', route: { path: '/homeliving', query: {type:'bedroom',subcategory:'blanketscushions',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Dressing Room', route: { path: '/homeliving', query: {type:'bedroom',subcategory:'dressingroom',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                
                ],
              },
              {
                label: 'dining room',
                route: { path: '/homeliving', query: {type:'dining room',subcategory:'tablecloths', reload: this.viewKey + 1 } },
                subsubmenu: [
                  { label: 'TableCoths', route: { path: '/homeliving', query: {type:'dining room',subcategory:'tablecloths',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'TableWare', route: { path: '/homeliving', query: {type:'dining room',subcategory:'tableware',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'GlassWare', route: { path: '/homeliving', query: {type:'dining room',subcategory:'glassware',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Cultery', route: { path: '/homeliving', query: {type:'dining room',subcategory:'cultery',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: ' Table Accessories', route: { path: '/homeliving', query: {type:'dining room',subcategory:'tableaccessories',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Trays', route: { path: '/homeliving', query: {type:'dining room',subcategory:'trays',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Coffee', route: { path: '/homeliving', query: {type:'dining room',subcategory:'coffee',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                ],
              },
              {
                label: 'kitchen',
                route: { path: '/homeliving', query: {type:'kitchen',color:  this.$route.query.color, reload: this.viewKey + 1 } },
                subsubmenu: [
                  { label: 'Kitchen Storage', route: { path: '/homeliving', query: {type:'kitchen',subcategory:'kitchenstorage',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Kitchen Accessories', route: { path: '/homeliving', query: {type:'kitchen',subcategory:'kitchenaccessories',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Kitchen Textiles', route: { path: '/homeliving', query: {type:'kitchen',subcategory:'kitchentextiles',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'HouseHold Appliancies', route: { path: '/homeliving', query: {type:'kitchen',subcategory:'householdappliances',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  
                ],
              },
              {
                label: 'bathroom',
                route: { path: '/homeliving', query: {type:'bathroom',color:  this.$route.query.color, reload: this.viewKey + 1 } },
                subsubmenu: [
                  { label: 'Basic Towels', route: { path: '/homeliving', query: {type:'bathroom',subcategory:'basictowels',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Towels', route: { path: '/homeliving', query: {type:'bathroom',subcategory:'towels',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'BathRobes', route: { path: '/homeliving', query: {type:'bathroom',subcategory:'bathrobes',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Bathroom Sets', route: { path: '/homeliving', query: {type:'bathroom',subcategory:'bathroomsets',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Bathroom Baskets', route: { path: '/homeliving', query: {type:'bathroom',subcategory:'bathroombaskets',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  { label: 'Bath Mats', route: { path: '/homeliving', query: {type:'bathroom',subcategory:'bathmats',color:  this.$route.query.color, reload: this.viewKey + 1 } } },
                  { label: 'Curtains', route: { path: '/homeliving', query: {type:'bathroom',subcategory:'curtains',color:  this.$route.query.color, reload: this.viewKey + 1 } }  },
                  
                  
                ],
              },
              {
                label: 'laundry|cleaning',
                route: { path: '/homeliving', query: {type:'laundrycleaning',color:  this.$route.query.color, reload: this.viewKey + 1 } },
                
              },
            ],
          },
          {
            label: 'Makeup',
            route:null,
            submenu: [

            {
              label:'Eyes',
              route:{ path: '/makeup', query: {type:'eyes',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            {
              label:'Lips',
              route:{ path: '/makeup', query: {type:'lips',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            {
              label:'Face',
              route:{ path: '/makeup', query: {type:'face',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            {
              label:'Shade Finder',
              route:{ path: '/makeup', query: {type:'shadefinder',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            
            {
              label:'Nail Polish',
              route:{ path: '/makeup', query: {type:'nailpolish',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            {
              label:'Accessories',
              route:{ path: '/makeup', query: {type:'accessories',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },

            ],
          },
          {
            label: 'Accessories',
            route:null,
            submenu: [

            {
              label:'Belts',
  route:{ path: '/accessories', query: {type:'belts',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            {
              label:'Jewlery',
  route:{ path: '/accessories', query: {type:'jewlery',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            {
              label:'Hats',
  route:{ path: '/accessories', query: {type:'hats',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            
            {
              label:'Scarves',
  route:{ path: '/accessories', query: {type:'scarves',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
            
            {
              label:'Socks|Tights',
  route:{ path: '/accessories', query: {type:'sockstights',color: this.$route.query.color, reload: this.viewKey + 1  } },
            },
           

            ],
          },
      
        ],
      };
    },
    methods: {
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      toggleSubmenu(index) {
        this.submenuActive = this.submenuActive === index ? null : index;
        this.subsubmenuActive = null;
      },
      toggleSubsubmenu(subIndex, subsubIndex) {
        this.subsubmenuActive = this.subsubmenuActive === subIndex + '-' + subsubIndex ? null : subIndex + '-' + subsubIndex;
      },
      closeMenu() {
        this.showMenu = false;
      },
    },
  };
  </script>
  
  
  
  <style> .dd{display:none} @media only screen and (max-width: 900px) { .dd { display: inline; } .menu-popup { position: fixed; top: 0; left: 0; width: 250px; height: 100%; background-color: #1d1f21; padding: 20px; border-right: 1px solid #333; box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1); z-index: 1000; overflow:auto;
   } .menu { display: none; list-style: none; margin: 0; padding: 0; text-align: left; } .menu.active { transform: translateX(0); display: block; } .menu-header { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; } .menu li { margin-bottom: 10px; border-bottom: 1px solid #333; } .menu a, .menu .dropdown-toggle { display: block; padding: 10px; color: #fff; text-decoration: none; font-family: Arial, sans-serif; } .menu a:hover, .menu .dropdown-toggle:hover { background-color: #333; } .menu .dropdown-menu { padding: 0; margin: 0; list-style: none; position: absolute; top: 100%; left: 0; width: 100%; background-color: #1d1f21; border: none; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); } .menu .dropdown-item { padding: 10px; color: #fff; text-decoration: none; font-family: Arial, sans-serif; } .menu .dropdown-item:hover { background-color: #333; } .menu .submenu, .menu .subsubmenu { padding-left: 20px; list-style: none; display: none; } .menu .submenu.active, .menu .subsubmenu.active { display: block; } .menu .submenu-item, .menu .menu-item { display: flex; align-items: center; } .menu .item-label { cursor: pointer; } .menu .item-icon { margin-left: 5px; cursor: pointer; } .fade-enter-active, .fade-leave-active { transition: all 0.3s ease-out; } .fade-enter-from, .fade-leave-to { transform: translateX(-100%); opacity: 0; } }  .close-btn{margin-left: 15px;} 
   </style>