<template >

    <div class="flex-container" style="background-color:white; z-index:1000;">
      
     <aside  v-if="isSidebarVisible" >
     
       <div class="y-auto col">
     <div class="fil" >
      <button class="close-btn" @click="closeSidebar" style="margin:10PX">&times;</button>
      <div class="filter-area px-2 col">
    
       <h3>Filter</h3>
        <div class="filter-item col">
         <h3>Color</h3>
         <div class="color-circles" style="margin-top:5px;">
           <div
             v-for="color in colors"
             :key="color"
             @click="Color(color)"
             :class="{ 'selected-color': selectedColor === color }"
             class="color-circle"
             :style="{ backgroundColor: color, border: '1px solid gray' }"
           ></div>
         </div>
       </div>
         <div class="filter-item col">
       <h3>Category</h3>
       <select v-model="selectedItem" style="color:black; margin-top:5px;" >
         <option v-for="category in categories" :key="category.name" :value="category.name">{{ category.name }}</option>
       </select>
     </div>
     
     <div class="filter-item col" v-if="selectedItem">
       <h3>Subcategory</h3>
       <select v-model="selectedSubcategory" style="color:black;margin-top:5px;" >
         <option v-for="subcategory in getSubcategories(selectedItem)" :key="subcategory" :value="subcategory" >{{ subcategory }}</option>
       </select>
     </div>
     <div class="filter-item col" v-if="selectedSubcategory">
    <h3>Sub-Subcategory</h3>
    <select v-model="selectedSubSubcategory" style="color:black;margin-top:5px;">
      <option v-for="subsubcategory in getSubSubcategories(selectedItem, selectedSubcategory)" :key="subsubcategory" :value="subsubcategory">{{ subsubcategory }}</option>
    </select>
  </div>
     
     <button @click="selectColor(selectedColor)">Apply Filter</button>
     
     </div>
    
   
     </div>
     
     </div>
     </aside>
     <button class="filbtn" v-if="!isSidebarVisible" @click="toggleSidebar" style="background-color: red; width: 100%; border-radius: 8px;  position: fixed;
    bottom: 0;
    
    left: 0;
    z-index: 1000;
    font-size: 14;
    font-weight: bold;
    ">Filter</button>
    
        </div>
      
   </template>
   
   <script>
   //import NavbarView from "@/components/NavbarView.vue";
   //import NavRespView from "@/components/NavRespView.vue";
   //import ColorCircles from "@/views/ColorCircles.vue";
  // import Footer from "@/components/Footer.vue";
   //import VueMasonryWall from "vue-masonry-wall";
   
   
   import axios from 'axios';
   export default {
    beforeRouteLeave(to, from, next) {
      // Reset the filter values
      this.selectedColor = null;
      this.selectedItem = null;
      this.selectedSubcategory = null;
      this.selectedSubSubcategory = null;
  
      // Call next() to continue the navigation
      next();
    },
     props: {
       footer: Boolean,
     },
     components: {
     
     },
     data() {
       return {
        isSidebarVisible: false,
        keyword: "",
         colors: [
           "red",
           "orange",
           "yellow",
           "green",
           "turquoise",
           "blue",
           "brown",
           "#800080",
           "pink",
           "white",
           "gray",
           "black",
           "#f88e55", // "saumon" color with hexadecimal code
           "#f5f5dc", // "beige" color with hexadecimal code
         ],
         categories: [
           { name: 'makeup', subcategories: ['lips', 'eyes', 'face','shade finder','nail polish','accessories'] },
           { name: 'homeliving', subcategories: ['furniture', 'decoration', 'diningroom','kitchen','bathroom','laundrycleaning'] },
           { name: 'clothing', subcategories: ['men', 'women'] },
           { name: 'accessories', subcategories: ['belts', 'jewelery', 'hats','scarves','sockstights'] },
          
           
         ],
         selectedSubSubcategory: null,
      subSubcategories: {
        // Define your sub-subcategories here, similar to how you did for subcategories
        'clothing': {
          'women': [ 'jackets',
            'coats',
            'trench coats',
            'blazers',
            'waistcoatsgilets',
            'dresses',
            'jumpsuits',
            'topsbodysuits',
            'shirts',
            't-shirts',
            'sweatshirts',
            'knitwear',
            'trousers',
            'jeans',
            'shortsskorts',
            'skirts',
            'shoes',
            'lingeriepyjamas',
            'suits'],
          'men': [ 'jackets',
            'coats',
            'trench coats',
            'hoodies',
            'sweatshirts',
            'tracksuits',
            'trousers',
            'jeans',
            'shirts',
            't-shirts',
            'sweaters',
            'cardigans',
            'overshirts',
            'suits',
            'blazers',
            'poloshirts',
            'shorts',
            'shoes']
        },
        'homeliving': {
          'furniture': [   'chairsarmchairs',
            'stoolsbenches',
            'sofas',
            'tables',
            'shelves',
            'beds furniture'],
          'bedroom': ['bedlinen',
            'quiltcovers',
            'bedspreadscovers',
            'fillingsprotectors',
            'blanketscushions',
            'dressing room'],
          'dinigroom': [ 'tablecloths',
                'tableware',
                'glassware',
                'cutlery',
                'table accessories',
                'trays',
                'coffee'],
          'kitchen': ['kitchen storage',
                'kitchen accessories',
                'kitchen textiles',
                'household appliances'],
          'bathroom': [ 'basic towels',
                'towels',
                'bathrobes',
                'bathroom sets',
                'bathroom baskets',
                'bath mats',
                'curtains'],
          'decoration': [ 'rugs',
            'mirrors',
            'lighting',
            'vasesflowerpots',
            'candlescandleholders',
            'baskets',
            'framesboxes',
            'accessories',
            'curtains',
            'cushionsfilling',
            'blankets']
        },
       
        // ... add more sub-subcategories as needed ...
      },
        
         selectedSubcategory: null,
         selectedColor: null,
         selectedItem: null,
         items: [{ image: '' },
        
        ],
        masonryOptions: {
          width: 100, // Largeur par défaut
          padding: 1, // Rembourrage par défaut
        },
         textColor: "black",
      
         activeColor:'',
         subcategory:'',
         type:'',
        
         intervalId: null,
       loading : false,
       };
     },
     computed: {
    isMobile() {
      return this.$screen.mdAndDown;
    },
  },
       mounted(){

       
       // Change banner every 5 seconds (adjust the interval as needed)
       this.intervalId = setInterval(this.changeBanner, 50000);
      
  
  
     },
     methods: {
       getColorName(color) {
         // Map color values to names
         const colorNames = {
           "#f88e55": "saumon",
           "#f5f5dc": "cream",
           "#800080": "lilac",
         };
   
         // Return the color name if it exists, or the original color value otherwise
         return colorNames[color] || color;
       },
       toggleSidebar() {
        this.isSidebarVisible = !this.isSidebarVisible;
        document.body.style.overflow = this.isSidebarVisible ? 'hidden' : 'auto';
        window.scrollTo({ top: 0, behavior: 'auto' });
      },
      Color(color){
        this.selectedColor =  this.getColorName(color);
      },
  
       selectColor(color) {
         this.loading = true;
         this.selectedColor =  this.getColorName(color);
         console.log(this.selectedColor);
         if (this.selectedColor == null) {
         this.selectedColor = this.$route.query.color;
     }
   
        if (this.selectedItem) {
         // API request to /{selectedCategory}/{selectedColor}
        
           if (this.selectedSubcategory) {
            if (this.selectedSubSubcategory){
              const newQuery = {
         ...this.$route.query,
         color: this.selectedColor,
         colorName: this.selectedColor,
         type: this.selectedSubcategory,
         subcategory:this.selectedSubSubcategory,
  
       };
       this.$router.push({ path: `/${this.selectedItem}`, query: newQuery });
     
      
            }else{
              const newQuery = {
         ...this.$route.query,
         color: this.selectedColor,
         colorName: this.selectedColor,
         type: this.selectedSubcategory,
  
         subcategory:null,
        
       };
       
       this.$router.push({ path: `/${this.selectedItem}`, query: newQuery });
   
    
            }
       
       
       } else{
         const newQuery = {
         ...this.$route.query,
         color: this.selectedColor,
         colorName: this.selectedColor,
         type: null,
         subcategory:null,
        
       };
       this.$router.push({ path: `/${this.selectedItem}`, query: newQuery });
       }
         
       }  else {
         const newQuery = {
         ...this.$route.query,
         color: this.selectedColor,
         colorName: this.selectedColor,
         type: null,
         subcategory:null,
       };
       this.$router.push({ path: this.$route.path, query: newQuery });
   
       }
       this.currentBanner = this.banners[this.currentBannerIndex];
       this.loading = false;
      
  
      
       },
       searchPhotos() {
         console.log("test");
   
    
          axios.get(`/all/${this.$route.query.color}/${this.keyword}`)
    .then(response => {
      this.items = response.data;
      console.log(response.data);
      this.$emit('search-updated', response.data);
      this.$router.push({ name: 'searchresults', query: { items: response.data, color: this.$route.query.color} });
    })
    .catch(error => {
      console.error(error);
      // Redirect to 'searchresults' with null items
      this.$router.push({ name: 'searchresults', query: { items: null, color: this.$route.query.color } });
    });
  
      },
      
         getSubcategories(category) {
         const selectedCategory = this.categories.find((c) => c.name === category);
         return selectedCategory ? selectedCategory.subcategories : [];
       },
       
       closeSidebar() {
        this.isSidebarVisible = !this.isSidebarVisible;
        document.body.style.overflow = this.isSidebarVisible ? 'hidden' : 'auto';
      },
  
      
       getSubSubcategories(subcategory, subsubcategory) {
      if (this.subSubcategories[subcategory] && this.subSubcategories[subcategory][subsubcategory]) {
        return this.subSubcategories[subcategory][subsubcategory];
      }
      return [];
    },
     },
     created() {
     
   },
   
   
   };
   </script>
   
   <style scoped>
   .filbtn{
    display:none;
   }
   
   @media screen and (max-width: 900px) {
      /* Styles spécifiques pour les écrans mobiles */
      .filbtn{
    display:inline;
   }
      .fil{
     width:330px;
   
      
   }
   .color-circles {
     display: grid;
     grid-template-columns: repeat(5, 1fr);
     gap: 5px;
   }
   
   .color-circle {
     width: 25px;
     height: 25px;
     border-radius: 50%;
     cursor: pointer;
     opacity: 0.7;
     transition: opacity 0.3s ease; /* Add transition for a smoother effect */
   }
   
   
   
   .filter-item {
     margin-bottom: 15px; /* Espacement entre les deux filtres */
     height:100px;
   }
   
   .filter-item select {
     appearance: none;
     border: 1px solid #ccc;
     border-radius: 5px;
     padding: 8px;
     font-size: 16px;
     width: 100%;
     cursor: pointer;
     background-color: #fff;
     position: relative;
     z-index: 1;
     outline: none;
     transition: border-color 0.3s ease;
     
     &:focus {
       border-color: #000;
     }
   
     &:focus-within {
       box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
     }
   
     &::-ms-expand {
       display: none;
     }
   }
   
   .filter-item select option {
     padding: 8px;
     font-size: 16px;
    
     color: #333;
   }
   
   .filter-item select option:hover {
     background-color: #f5f5f5;
   }
   .all {
     flex: 1;
     background-color:black;
   
   /*  margin-top: 5PX;*/
     
   }
   #all {
    
     margin: 0;
     padding: 0;
   }
  
   
   
  
   
   
  
   
  
   
   .view-button {
     background-color: black;
     color: white;
     border: none;
     padding: 10px 20px;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     cursor: pointer;
     transition: background-color 0.3s ease;
   }
   
   
   
   .color-box {
   
     color: white;
     text-align: center;
     padding: 20px 0;
     font-size: 24px;
     font-weight: bold;
     border-radius: 10px;
     margin-top: 8px;
     font-family: "Times New Roman", Times, serif;
   }
  
   
  
   /* Add fade-in animation keyframes */
   @keyframes fadeInUp {
     from {
       opacity: 0;
       transform: translateY(20px);
     }
     to {
       opacity: 1;
       transform: translateY(0);
     }
   }
    
      
   
   aside {
     width: 100%;
     z-index: 2;
     
     max-width: 350px;
     display: flex;
     position: sticky;
     top: 0;
      overflow-y: auto;  /* Enable vertical scrollbar when content overflows */
     height: 100vh;
     border-right: 1px solid var(--second-background-color);
    }
   
   
   .selected-color {
     border: 50px solid black; /* Add a black border for the selected color */
     opacity: 4; /* Remove transparency for the selected color */
   }
   
   button {
     background-color: #000; /* Button background color */
     color: #fff; /* Button text color */
     padding: 10px 20px; /* Padding around button text */
     border: none; /* Remove default button border */
     border-radius: 5px; /* Add a slight border radius for a rounded appearance */
     cursor: pointer; /* Add a pointer cursor on hover for better user interaction */
     font-size: 16px; /* Adjust font size as needed */
     transition: background-color 0.3s ease; /* Smooth transition for background color change */
     margin-bottom: 50px;
   
     /* Additional styles for hover effect */
     &:hover {
       background-color: #333; /* Darken the background color on hover */
     }
     button {
    background-color: #000; /* Button background color */
    color: #fff; /* Button text color */
    padding: 10px 20px; /* Padding around button text */
    border: none; /* Remove default button border */
    border-radius: 5px; /* Add a slight border radius for a rounded appearance */
    cursor: pointer; /* Add a pointer cursor on hover for better user interaction */
    font-size: 16px; /* Adjust font size as needed */
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
    margin-bottom: 10px; /* Adjust margin as needed */
  
    /* Additional styles for hover effect */
    &:hover {
      background-color: #333; /* Darken the background color on hover */
    }
  }
  
  /* Styles for the close button */
  button.close-btn {
    background-color: #ff4d4d; /* Red background color for close button */
    margin-top: 10px; /* Adjust margin as needed */
  
    /* Additional styles for hover effect */
    &:hover {
      background-color: #e63946; /* Darken the background color on hover */
    }
  }
   }
  
  
   .search-container { margin-left: 250px;   } 
   .search-wrapper { width: 100%; max-width: 800px; margin: 0 auto;  }
    .search { display: flex; 
      align-items: center; 
      padding: 10px 15px;
       background-color: var(--header-background-color);
       background-color: #fff;
        border-radius: 20px; box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1); }
         .search input { flex: 1; border: none; background: none; margin-right: 10px; outline: none; width: 500px; } 
         .search i { font-size: 1.2rem; cursor: pointer; }
         .search:hover {  border: 2px solid black;  }
  
         .item img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }

    
h3{
  color:black;
}
    
    
    .gal{
      width: 393px;
    }
    .gallery {
    
     
    margin-right:2px;
    margin-left: 2px;
   
    
  }
  
  .search-container {
    margin-left: 2px;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    margin-top: 20px; /* Add some top margin for better spacing */
  }
  
  .search-wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .search {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: var(--header-background-color);
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  }
  
  .search input {
    flex: 1;
    border: none;
    background: none;
    margin-right: 10px;
    outline: none;
    width: 300px; /* Set the input width to 100% */
  }
  
  .search i {
    font-size: 1.2rem;
    cursor: pointer;
  }
  
  .search:hover {
    border: 2px solid black;
  }
  .sticky-color-circles{
    
       z-index: 0;
   }
   .close-btn{margin-left: 300px;}
  }
    
   
   </style>