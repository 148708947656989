<template>
  <div class="sticky-color-circles">
    <div class="color-circles">
      <div class="vv">
        <button
        v-for="(couleur, index) in colors"
        :key="index"
        class="color-button"
        @click="navigateToColor(couleur)"
        :style="{
          backgroundColor: couleur,
          width: '25px', /* Adjust the button size as needed */
          height: '35px', /* Adjust the button size as needed */
          border: '2px solid white', /* White border */
          boxShadow: '0 0 0 3px black inset', /* Gray border */
          margin:'3px'
        }"
      >

      </button>
      </div>
     
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: [
        "red",
        "orange",
        "yellow",
        "green",
        "turquoise",
        "blue",
        "brown",
         "#800080",
        "pink",
        "white",
        "gray",
        "black",
       "#f88e55", // "saumon" color with hexadecimal code
      "#f5f5dc", // "beige" color with hexadecimal code
      ],
    };
  },
  methods: {
   getColorName(color) {
    // Map color values to names
    const colorNames = {
      "#f88e55": "saumon",
      "#f5f5dc": "cream",
      "#800080":"lilac",
    };

    // Return the color name if it exists, or the original color value otherwise
    return colorNames[color] || color;
  },

  navigateToColor(color) {
    // Use the getColorName method to get the name
    const colorName = this.getColorName(color);

    this.$router.push({
      path: `/${colorName}/allproducts`,
      query: { color: color,colorName: colorName},
    });
  }, }
};
</script>

<style scoped>
.sticky-color-circles {
  position: sticky;
  top: 0; /* Adjust this value according to your design */
  background-color: black; /* Optional background color */
  z-index: 1000; /* Adjust the z-index value as needed */
}

.color-circles {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
 
   background-color:black;
}


</style>