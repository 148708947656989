<template>
  <header>
    <div class="row p-1 px-1 md-px-2 justify-content-center">
      <div class="categories text-center">
        <div class="color-circles">
          <div v-for="(color, index) in colors" :key="index" @click="selectColor(color)">
            <div :style="{ backgroundColor: color , opacity:0.6 }" class="color-circle"></div>
          </div>
        </div>
      </div>
      <div class="search-container text-center">
        <div class="search">
          <input v-model="keyword" placeholder="Choose Color then Enter keyword" @change="searchPhotos" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';
export default {
  name: "HeaderComponent",
  data() {
    return {
      coulor: "",
      keyword: "",
      photos: [],
      colors: [
        "red",
        "orange",
        "yellow",
        "green",
        "turquoise",
        "blue",
        "brown",
         "#800080",
        "pink",
        "white",
        "gray",
        "black",
       "#f88e55", // "saumon" color with hexadecimal code
      "#f5f5dc", // "beige" color with hexadecimal code
      ],
    };
  },
  methods: {
    selectColor(color) {
      this.coulor = color;
     // this.searchPhotos();
    },
     getColorName(color) {
    // Map color values to names
    const colorNames = {
      "#f88e55": "saumon",
      "#f5f5dc": "cream",
      "#800080":"lilac",
    };

    // Return the color name if it exists, or the original color value otherwise
    return colorNames[color] || color;
  },
    searchPhotos() {
       console.log("test");
    const color = this.getColorName(this.coulor);
      /*axios.get(`/all/${color}/${this.keyword}`)
        .then(response => {
          this.photos = response.data;
          console.log(response.data);
          this.$emit('search-updated', response.data);
          this.$router.push({ name: 'searchresults', query: { photos: response.data, color:color } });
        })
        .catch(error => {
          console.error(error);
        });*/
        axios.get(`/all/${color}/${this.keyword}`)
  .then(response => {
    this.photos = response.data;
    console.log(response.data);
    this.$emit('search-updated', response.data);
    this.$router.push({ name: 'searchresults', query: { photos: response.data, color: color } });
  })
  .catch(error => {
    console.error(error);
    // Redirect to 'searchresults' with null photos
    this.$router.push({ name: 'searchresults', query: { photos: null, color: color } });
  });

    },
  },
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
}

.color-circles {
  display: flex;
  gap: 10px;
  margin-right:20px;
  margin-left:50px;
}

.color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #ddd;
}

.search {
  width: 100%;
  max-width: 800px; /* Largeur maximale de la barre de recherche */
  margin: 0 auto; /* Centre la barre de recherche horizontalement */
 
}

.search input {
  width: 380px;
  padding: 12px;
  border: 1px solid black;
  border-radius: 5px ;
  box-sizing: border-box;
}
</style>
