<template>
  <div>
    <div class="filter-item col">
      <label>Color</label>
      <div class="color-circles">
        <div
          v-for="color in colors"
          :key="color"
          @click="selectColor(color)"
          :class="{ 'selected-color': selectedColor === color }"
          class="color-circle"
          :style="{ backgroundColor: color, border: '1px solid gray' }"
        ></div>
      </div>
    </div>

    <div class="filter-item col">
      <label>Categories</label>
      <!-- Select List -->
      <select v-model="selectedItem" @change="redirectToFilteredPath">
        <option value="clothing">Clothing</option>
        <option value="homeliving">Home Living</option>
        <option value="makeup">Makeup</option>
        <option value="accessories">Accessories</option>
        <!-- Add more options as needed -->
      </select>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colors: [
        "red",
        "orange",
        "yellow",
        "green",
        "turquoise",
        "blue",
        "brown",
        "#800080",
        "pink",
        "white",
        "gray",
        "black",
        "#f88e55", // "saumon" color with hexadecimal code
        "#f5f5dc", // "beige" color with hexadecimal code
      ],
      selectedColor: null,
      selectedItem: null,
    };
  },
  methods: {
    getColorName(color) {
      // Map color values to names
      const colorNames = {
        "#f88e55": "saumon",
        "#f5f5dc": "cream",
        "#800080": "lilac",
      };

      // Return the color name if it exists, or the original color value otherwise
      return colorNames[color] || color;
    },
    selectColor(color) {
      const colorName = this.getColorName(color);
      this.selectedColor = colorName;
      console.log(this.selectedColor);
     
        const path = `/${this.selectedItem}`;
        const query = { color: this.selectedColor };
        this.$router.push({ path: path, query: query });
      
     if (!this.selectedItem) {
        const path = `/${this.selectedColor}/allproducts`;
        const query = { color: this.selectedColor,colorName:this.selectedColor };
        this.$router.push({ path: path, query: query });
      }
    },
    redirectToFilteredPath() {
    
        const path = `/${this.selectedItem}`;
        const query = { color: this.selectedColor };
        this.$router.push({ path: path, query: query });
  
      
    },
  },
};
</script>

<style scoped>
.color-circles {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
}

.color-circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease; /* Add transition for a smoother effect */
}

.selected-color {
  border: 2px solid black; /* Add a black border for the selected color */
  opacity: 1; /* Remove transparency for the selected color */
}

.filter-item {
  margin-bottom: 15px; /* Espacement entre les deux filtres */
}


.filter-item select {
  width: 100%;
  padding: 8px;
  border: 1px solid black; /* Border color */
  border-radius: 5px;
  cursor: pointer;
  background-color: #f8f8f8; /* Background color */
  color: #333; /* Text color */
  
}

/* Style for the dropdown arrow */
.filter-item select::after {
  content: '\25BC'; /* Unicode character for a downward arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 16px;
  color: #555; /* Arrow color */
}

/* Styles for the dropdown list */
.filter-item select option {
  background-color: #fff;
  color: #333;
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Border color between options */
}

/* Hover effect for options */
.filter-item select option:hover {
  background-color: #f0f0f0;
}
</style>
