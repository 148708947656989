import Vue from "vue";
import Vuex from "vuex";
import getters from "./app/getters";
import mutations from "./app/mutations";
import actions from "./app/actions";
import color from './app/color'; // Import the color module

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    signUpForm: {
      username: "",
      password: "",
    },
    albums: [],
    photosTimeline: [],
    searchText: "",
    filterSection: false,
    backdrop: false,
    fetchStatus: false,
    darkmode: false,
    categories: [
      "red",
      "orange",
      "yellow",
      "green",
      "turquoise",
      "blue",
      "brown",
      "lilac",
      "pink",
      "white",
      "gray",
      "black",
      "#ff9999", // "saumon" color with hexadecimal code
      "#f5f5dc", // "beige" color with hexadecimal code
    ],
  },
  getters,
  mutations,
  actions,
  modules: {
    // const moduleA = {
    //   state: () => ({ ... }),
    //   mutations: { ... },
    //   actions: { ... },
    //   getters: { ... }
    // }
    // const moduleB = {
    //   state: () => ({ ... }),
    //   mutations: { ... },
    //   actions: { ... }
    // }
    // const store = createStore({
    //   modules: {
    //     a: moduleA,
    //     b: moduleB
    //   }
    // })
    // store.state.a // -> `moduleA`'s state
    // store.state.b // -> `moduleB`'s state
    color,
  },
});
