// Create a store module named 'color'
// src/store/modules/color.js

const state = {
    selectedColor: null,
  };
  
  const mutations = {
    SET_SELECTED_COLOR(state, color) {
      state.selectedColor = color;
    },
  };
  
  const actions = {
    setSelectedColor({ commit }, color) {
      commit('SET_SELECTED_COLOR', color);
    },
  };
  
  const getters = {
    selectedColor: (state) => state.selectedColor,
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  