<template>
  <div class="home-view">
    <!--SimpleHeader /--> <!-- Include the SimpleHeader component here -->
    <div class="home-content">
      <div class="container">
          <div class="logo">
            <img src="@/assets/sansf.png" alt="Logo" class="animated-logo" />
          </div>
       </div>
       
      <h1>LIVE LIFE IN COLORS <br>EACH DAY IS A CANVAS, EACH MOMENT IS A UNIQUE SHADE.</h1>
      
      <!-- Add your home page content here -->
      <button class="start-button" @click="startAdventure">Start The Adventure !</button>
       <div class="footer-container">
      <div class="footer-background"></div> <!-- Background div -->
      
      <div class="footer-content">
      <router-link to="/aboutus" class="footer-content">
      About us
    </router-link>
    </div>
      <div class="social-icons">
        <a href="https://www.facebook.com/" target="_blank" class="social-icon">
          <font-awesome-icon :icon="['fab', 'fa-facebook']" size="2x" />
        </a>
        <a href="https://www.instagram.com/" target="_blank" class="social-icon">
          <font-awesome-icon :icon="['fab', 'fa-instagram']" size="2x" />
        </a>
       
      </div>
      <div class="footer-content">
        Made with <span class="heart">♥</span> by WD
      </div>
      </div>
    </div>
   
  </div>
  
</template>

<script>
//import SimpleHeader from '@/components/SimpleHeader.vue'
//import Footer from '@/components/Footer.vue'

export default {
  name: "HomeView",
  components: {
    //SimpleHeader, // Add the SimpleHeader component here
    //Footer
  },
  methods: {
    startAdventure() {
      // Programmatically navigate to the /colors route
      this.$router.push('/colors');
    },
  },
};
</script>

<style lang="scss" scoped>
/* Add your CSS styles for HomeView here */
.home-content {
  background-image: url("@/assets/fond2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 20px; /* Adjust margin for mobile devices */
  margin-bottom: 20px; /* Adjust margin for mobile devices */
}

/* Additional styles for your content */
.home-content {
  text-align: center;
  color: black; /* Text color on top of the background image */
  font-family: "cursive"; /* Keep the cursive font family */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.home-content h1 {
  font-size:50px; /* Adjust font size for mobile devices */
  color: black; /* Keep the text color */
}

.logo img {
  height: 200px; /* Adjust the logo size for mobile devices */
}

.animated-logo {
  animation: fadeInUp 1s ease;
}

.start-button {
  background-color: #000; /* Keep the black background color */
  color: #fff; /* Keep the white text color */
  border: none;
  border-radius: 5px;
  padding: 15px 30px; /* Adjust the size of the button */
  font-size: 16px; /* Adjust font size for mobile devices */
  font-weight: bold;
  cursor: pointer;
  display: block;
  margin: 0 auto; /* Center horizontally */
}

.footer-content {
  text-align: center;
  color: black; /* Keep the text color */
}

.heart {
  color: red; /* Keep the red heart color */
  font-size: 24px; /* Keep the font size of the heart */
}

.social-icon {
  margin-left: 10px;
  color: inherit;
  transition: all 0.1s ease;
}

.social-icon:hover {
  filter: drop-shadow(0 2px 10px var(--button-color));
}

/* Add media query for screens smaller than 600px (adjust as needed) */
@media screen and (max-width: 600px) {
  .home-content h1 {
    font-size: 18px; /* Adjust font size for smaller screens */
  }

  .logo img {
    height: 100px; /* Adjust the logo size for smaller screens */
  }

  .start-button {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .heart {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .social-icon {
    margin-left: 3px; /* Adjust margin for smaller screens */
  }
}
</style>