import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from 'axios';
import {
  faLock,
  faUser,
  faTextHeight,
  faLink,
  faImage,
  faBars,
  faImages,
  faSpinner,
  faThumbsUp,
  faComment,
  faUpDown,
  faLeftRight,
  faXmark,
  faRightFromBracket,
  faEllipsisVertical,
  faMoon,
  faSun,
  faFilter,

  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare, faKeyboard } from "@fortawesome/free-regular-svg-icons";
import { faGithub,faFacebook,faInstagram,faTwitter,faTiktok } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faUser,
  faLock,
  faSquare,
  faTextHeight,
  faKeyboard,
  faLink,
  faImage,
  faBars,
  faImages,
  faSpinner,
  faThumbsUp,
  faComment,
  faUpDown,
  faLeftRight,
  faXmark,
  faRightFromBracket,
  faEllipsisVertical,
  faMoon,
  faSun,
  faFilter,
  faGithub,
  faFacebook,
  faInstagram,
  faTwitter,
  faTiktok,
 faBars,
 faChevronDown,  
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
axios.defaults.baseURL = 'https://www.color-me-chic.com/api';


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
