import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import GalleryRed from "../views/GalleryRed.vue";
import ColorsView from "../views/ColorsView.vue";
import FemmeClothing from "../views/clothing/women/FemmeClothing.vue";
import AccessoriesView from "../views/Accessories/AccessoriesView.vue";
import MakeupView from "../views/makeup/MakeupView.vue";
import HomelivingView from "../views/Homeliving/HomelivingView.vue";
import SearchResultsView from "../components/SearchResults.vue";

import AboutView from "../components/AboutView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/searchresults",
    name: "searchresults",
    component: SearchResultsView,
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: AboutView,
  },
  {
    path: "/blue/allproducts",
    name: "blue",
    component: GalleryRed,
  },
  {
    path: "/red/allproducts",
    name: "red",
    component: GalleryRed,
  },
  {
    path: "/orange/allproducts",
    name: "orange",
    component: GalleryRed,
  },
  {
    path: "/brown/allproducts",
    name: "brown",
    component: GalleryRed,
  },
  {
    path: "/green/allproducts",
    name: "green",
    component: GalleryRed,
  },

  {
    path: "/pink/allproducts",
    name: "pink",
    component:GalleryRed,
  },
  {
    path: "/cream/allproducts",
    name: "cream",
    component:GalleryRed,
  },
  {
    path: "/saumon/allproducts",
    name: "saumon",
    component:GalleryRed,
  },
  {
    path: "/lilac/allproducts",
    name: "purple",
    component: GalleryRed,
  },
  {
    path: "/white/allproducts",
    name: "white",
    component: GalleryRed,
  },
  {
    path: "/gray/allproducts",
    name: "gray",
    component: GalleryRed,
  },
  {
    path: "/turquoise/allproducts",
    name: "turquoise",
    component: GalleryRed,
  },
 
  {
    path: "/#ff9999/allproducts",
    name: "saumon",
    component: GalleryRed,
  },
  {
    path: "/#f5f5dc/allproducts",
    name: "beige",
    component: GalleryRed,
  },
  {
    path: "/black/allproducts",
    name: "black",
    component: GalleryRed,
  },
  {
    path: "/yellow/allproducts",
    name: "yellow",
    component: GalleryRed,
  },
  {
    path: "/colors",
    name: "colors",
    component: ColorsView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: '/clothing',
    name: 'clothing',
    component: FemmeClothing,
  },
  {
    path: '/homeliving',
    name: 'homeliving',
    component: HomelivingView,
  },
  {
    path: '/makeup',
    name: 'makeup',
    component: MakeupView,
  },
  {
    path: '/accessories',
    name: 'accessories',
    component: AccessoriesView,
  },
  
 
  
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.reload) {
    window.location.reload(true);
  } else {
    next();
  }
});

export default router;
