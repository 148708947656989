<template>
  <div class="flex-container">
     
    <aside >
      <div class="y-auto col">
    <div class="fil">
  
     <div class="filter-area px-2 col">
     <router-link :to="{ path: '/'}">
          <!-- Replace "Mycolor" text with an image -->
          <label class="logo p-1">
            <img src="@/assets/l1.png" alt="Logo" />
          </label>
        </router-link>
      <h3>Filter</h3>
       <div class="filter-item col">
        <label>Color</label>
        <div class="color-circles">
          <div
            v-for="color in colors"
            :key="color"
            @click="selectColor(color)"
            :class="{ 'selected-color': selectedColor === color }"
            class="color-circle"
            :style="{ backgroundColor: color, border: '1px solid gray' }"
          ></div>
        </div>
      </div>
        <div class="filter-item col">
      <label>Category</label>
      <select v-model="selectedItem"  >
        <option v-for="category in categories" :key="category.name" :value="category.name">{{ category.name }}</option>
      </select>
    </div>
    
    <div class="filter-item col" v-if="selectedItem">
      <label>Subcategory</label>
      <select v-model="selectedSubcategory" >
        <option v-for="subcategory in getSubcategories(selectedItem)" :key="subcategory" :value="subcategory" >{{ subcategory }}</option>
      </select>
    </div>
    <div class="filter-item col" v-if="selectedSubcategory">
   <label>Sub-Subcategory</label>
   <select v-model="selectedSubSubcategory">
     <option v-for="subsubcategory in getSubSubcategories(selectedItem, selectedSubcategory)" :key="subsubcategory" :value="subsubcategory">{{ subsubcategory }}</option>
   </select>
  </div>
    
    <button @click="selectColor(selectedColor)">Apply Filter</button>
    
    </div>
   
  
    </div>
    
    </div>
    </aside>
    
     <div class="gal" style="background-color: black;">
       <head><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"></head>
       <div class="row p-1 px-1 md-px-2 justify-content-center" >
      
       
       
     </div>
     <div class="divider"></div>
     <div class="espace">
       <div class="all"  >
           <NavbarView :color="this.$route.query.color" />
           <router-link to="/">
  <div class="icon">
    <i class="fas fa-arrow-left" style="color: white; font-size: 20px; margin-left: 5px;"></i>
    <span style="color: white; font-size: 14px; margin-left: 5px;">Home</span>
  </div>
</router-link>

            <div class="banner">
      <img v-if="!loading" :src="currentBanner.image" alt="Banner Image" />
      <div v-else class="banner-loading">
      Loading...
    </div>
    </div>
        <ColorCircles />
        <div class="wardrobe-text">
          <h2>Welcome to ColorMeChic - Your Ultimate Style Companion! </h2>
        </div>
  
        <div class="wardrobe-text">
          <p>At ColorMeChic, we believe in celebrating every aspect of your life with vibrant colors</p>
          <p>and exquisite styles. Our blog is your go-to destination for the latest trends and </p>
          <p>inspiration in the realms of fashion, home living, makeup, and accessories.</p>
          <p>Let us take you on a journey through the world of chic elegance and self-expression.</p>
          <p>ColorMeChic is not just a blog; it's a celebration of style and canvas for self-expression.</p>
        </div>
  
        <div class="image-with-text" >
          <img src="@/assets/6.png" alt="Banner Image"  />
         
        </div>
         <br/>
        <br/>
        <div class="image-with-text">
          
          <img src="@/assets/7.png" alt="Banner Image"  />
        
        </div>
         <br/>
        <br/>
          <div class="image-with-text">
          <img src="@/assets/8.png" alt="Banner Image"  />
          
        </div>
        <br/>
        <br/>
          <div class="image-with-text">
          <img src="@/assets/9.png" alt="Banner Image" style="margin-left:400px"/>
       
        </div>
         <br/>
        <br/>
        <br/>
       
        <Footer />
        </div>
     </div>
     
   </div>
  
       </div>
      
  </template>
   <script>
   import NavbarView from "@/components/NavbarView.vue";
   import ColorCircles from "@/views/ColorCircles.vue";
   import Footer from "@/components/Footer.vue";
   
   import axios from 'axios';
   export default {
    beforeRouteLeave(to, from, next) {
      // Reset the filter values
      this.selectedColor = null;
      this.selectedItem = null;
      this.selectedSubcategory = null;
      this.selectedSubSubcategory = null;
  
      // Call next() to continue the navigation
      next();
    },
     props: {
       footer: Boolean,
     },
     components: {
   
   Footer,
       NavbarView,
       ColorCircles,
   
     },
     data() {
       return {
   
        keyword: "",
         colors: [
           "red",
           "orange",
           "yellow",
           "green",
           "turquoise",
           "blue",
           "brown",
           "#800080",
           "pink",
           "white",
           "gray",
           "black",
           "#f88e55", // "saumon" color with hexadecimal code
           "#f5f5dc", // "beige" color with hexadecimal code
         ],
         categories: [
           { name: 'makeup', subcategories: ['lips', 'eyes', 'face','shade finder','nail polish','accessories'] },
           { name: 'homeliving', subcategories: ['furniture', 'decoration', 'diningroom','kitchen','bathroom','laundrycleaning'] },
           { name: 'clothing', subcategories: ['men', 'women'] },
           { name: 'accessories', subcategories: ['belts', 'jewelery', 'hats','scarves','sockstights'] },
          
           
         ],
         selectedSubSubcategory: null,
      subSubcategories: {
        // Define your sub-subcategories here, similar to how you did for subcategories
        'clothing': {
          'women': [ 'jackets',
            'coats',
            'trench coats',
            'blazers',
            'waistcoatsgilets',
            'dresses',
            'jumpsuits',
            'topsbodysuits',
            'shirts',
            't-shirts',
            'sweatshirts',
            'knitwear',
            'trousers',
            'jeans',
            'shortsskorts',
            'skirts',
            'shoes',
            'lingeriepyjamas',
            'suits'],
          'men': [ 'jackets',
            'coats',
            'trench coats',
            'hoodies',
            'sweatshirts',
            'tracksuits',
            'trousers',
            'jeans',
            'shirts',
            't-shirts',
            'sweaters',
            'cardigans',
            'overshirts',
            'suits',
            'blazers',
            'poloshirts',
            'shorts',
            'shoes']
        },
        'homeliving': {
          'furniture': [   'chairsarmchairs',
            'stoolsbenches',
            'sofas',
            'tables',
            'shelves',
            'beds furniture'],
          'bedroom': ['bedlinen',
            'quiltcovers',
            'bedspreadscovers',
            'fillingsprotectors',
            'blanketscushions',
            'dressing room'],
          'dinigroom': [ 'tablecloths',
                'tableware',
                'glassware',
                'cutlery',
                'table accessories',
                'trays',
                'coffee'],
          'kitchen': ['kitchen storage',
                'kitchen accessories',
                'kitchen textiles',
                'household appliances'],
          'bathroom': [ 'basic towels',
                'towels',
                'bathrobes',
                'bathroom sets',
                'bathroom baskets',
                'bath mats',
                'curtains'],
          'decoration': [ 'rugs',
            'mirrors',
            'lighting',
            'vasesflowerpots',
            'candlescandleholders',
            'baskets',
            'framesboxes',
            'accessories',
            'curtains',
            'cushionsfilling',
            'blankets']
        },
       
        // ... add more sub-subcategories as needed ...
      },
        
         selectedSubcategory: null,
         selectedColor: null,
         selectedItem: null,
         photos: [],
         textColor: "black",
         searchResults: [],
         activeColor:'',
         subcategory:'',
         type:'',
          banners: [],
         currentBannerIndex: 0,
         currentBanner: {},
         intervalId: null,
       loading : false,
       };
     },
       mounted(){
    localStorage.setItem('activeColor',this.$route.query.color);
    this.currentBanner = this.banners[this.currentBannerIndex];
       
       // Change banner every 5 seconds (adjust the interval as needed)
       this.intervalId = setInterval(this.changeBanner, 50000);
     },
     methods: {
       getColorName(color) {
         // Map color values to names
         const colorNames = {
           "#f88e55": "saumon",
           "#f5f5dc": "cream",
           "#800080": "lilac",
         };
   
         // Return the color name if it exists, or the original color value otherwise
         return colorNames[color] || color;
       },
       selectColor(color) {
         this.loading = true;
         this.selectedColor =  this.getColorName(color);
         console.log(this.selectedColor);
         if (this.selectedColor == null) {
         this.selectedColor = this.$route.query.color;
     }
        if (this.selectedItem) {
         // API request to /{selectedCategory}/{selectedColor}
        
           if (this.selectedSubcategory) {
            if (this.selectedSubSubcategory){
              const newQuery = {
         ...this.$route.query,
         color: this.selectedColor,
         colorName: this.selectedColor,
         type: this.selectedSubcategory,
         subcategory:this.selectedSubSubcategory,
       };
       this.$router.push({ path: `/${this.selectedItem}`, query: newQuery });
            }else{
              const newQuery = {
         ...this.$route.query,
         color: this.selectedColor,
         colorName: this.selectedColor,
         type: this.selectedSubcategory,
        
       };
       this.$router.push({ path: `/${this.selectedItem}`, query: newQuery });
            }
            
       
       } else{
         const newQuery = {
         ...this.$route.query,
         color: this.selectedColor,
         colorName: this.selectedColor,
        
       };
       this.$router.push({ path: `/${this.selectedItem}`, query: newQuery });
       }
         
       }  else {
         const newQuery = {
         ...this.$route.query,
         color: this.selectedColor,
         colorName: this.selectedColor
       };
       this.$router.push({ path: this.$route.path, query: newQuery });
       
   
        
   
       }
       this.currentBanner = this.banners[this.currentBannerIndex];
       this.loading = false;
      
       },
   
       searchPhotos() {
         console.log("test");
   
    
          axios.get(`/all/${this.$route.query.color}/${this.keyword}`)
    .then(response => {
      this.photos = response.data;
      console.log(response.data);
      this.$emit('search-updated', response.data);
      this.$router.push({ name: 'searchresults', query: { photos: response.data, color: this.$route.query.color} });
    })
    .catch(error => {
      console.error(error);
      // Redirect to 'searchresults' with null photos
      this.$router.push({ name: 'searchresults', query: { photos: null, color: this.$route.query.color } });
    });
  
      },
      
         getSubcategories(category) {
         const selectedCategory = this.categories.find((c) => c.name === category);
         return selectedCategory ? selectedCategory.subcategories : [];
       },
       
  
  
       changeBanner() {
         this.currentBannerIndex = (this.currentBannerIndex + 1) % this.banners.length;
         this.currentBanner = this.banners[this.currentBannerIndex];
       },
       getSubSubcategories(subcategory, subsubcategory) {
      if (this.subSubcategories[subcategory] && this.subSubcategories[subcategory][subsubcategory]) {
        return this.subSubcategories[subcategory][subsubcategory];
      }
      return [];
    },
     },
     created() {
    const selected = this.$route.query.colorName;
    const selectedColor = this.$route.query.color;
  
    if (selected !== null) {
      axios.get(`/all/${selected}`)
        .then(response => {
          this.photos = response.data;
        })
        .catch(error => {
          console.error(error);
        });
  
      axios.get(`/banner`) // Corrected backticks
        .then(response => {
          this.banners = response.data;
          this.currentBanner = this.banners[this.currentBannerIndex];
          this.intervalId = setInterval(this.changeBanner, 5000);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      axios.get(`/all/${selectedColor}`)
        .then(response => {
          this.photos = response.data;
        })
        .catch(error => {
          console.error(error);
        });
  
      axios.get(`/banner`) // Corrected backticks
        .then(response => {
          this.banners = response.data;
          console.log( this.banners);
          this.currentBanner = this.banners[this.currentBannerIndex];
          this.intervalId = setInterval(this.changeBanner, 5000);
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
   
   };
   </script>
   <style lang="scss" scoped>
  #all {
    background-color: black;
    margin: 0;
    padding: 0;
  }
  
  .gallery {
    background-color: black;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px; // Horizontal gap
    row-gap: 0; // Vertical gap
    justify-content: center;
    align-items: center;
    margin-right:5px;
    margin-left:5px;
    
  }
  
  .gallery-item {
     background-color: black;
    text-align: center;
    break-inside: avoid; /* Prevent breaks inside items to keep masonry effect */
  }
  
  .image-card {
    display: relative;
    position: relative;
  }
  
  .gallery-item img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    margin-bottom: 10px; /* Add margin between items for better visual separation */
  }
  
  .view-button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-button:hover {
    background-color: black;
  }
  
  .color-box {
     background-color: black;
    color: white;
    text-align: center;
    padding: 20px 0;
    font-size: 24px;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 8px;
    font-family: "Times New Roman", Times, serif;
  }
  .sticky-color-circles{
      position: sticky;
      top: 0;
      background-color: black;
      z-index: 1000;
  }
  
  .slogan {
    margin: 0;
  }
  /* Styles pour la bannière */
   .image-with-text {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  
    .image-with-text img {
      width: 50%; /* Adjust the width as needed */
      height: auto;
      border-radius: 8px;
         margin-left:400px;
    }
  
    .banner-text {
      position: absolute;
      top: 50%;
      left: 10%; /* Adjust the left position as needed */
      transform: translateY(-50%);
      color: white;
      width: 30%; /* Adjust the width as needed */
   
    }
  
    .banner-text h3 {
      font-size: 24px; /* Adjust the font size as needed */
      margin-bottom: 10px;
    }
  
    .banner-text p {
      font-size: 16px; /* Adjust the font size as needed */
      margin: 0;
    }
  .wardrobe-text {
    text-align: center;
    color: white;
    padding: 20px;
  }
  
  .wardrobe-text h2,
  .wardrobe-text p {
    opacity: 0;
    animation: fadeInUp 1s ease forwards;
  }
  
  .wardrobe-text h2 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .wardrobe-text p {
    font-size: 18px;
    margin: 0;
    margin-bottom: 10px;
  }
  
  /* Add fade-in animation keyframes */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fil{
     width:350px;
   
      
   }
   .color-circles {
     display: grid;
     grid-template-columns: repeat(5, 1fr);
     gap: 5px;
   }
   
   .color-circle {
     width: 25px;
     height: 25px;
     border-radius: 50%;
     cursor: pointer;
     opacity: 0.7;
     transition: opacity 0.3s ease; /* Add transition for a smoother effect */
   }
   
   
   
   .filter-item {
     margin-bottom: 15px; /* Espacement entre les deux filtres */
     height:100px;
   }
   
   .filter-item select {
     appearance: none;
     border: 1px solid #ccc;
     border-radius: 5px;
     padding: 8px;
     font-size: 16px;
     width: 100%;
     cursor: pointer;
     background-color: #fff;
     position: relative;
     z-index: 1;
     outline: none;
     transition: border-color 0.3s ease;
     
     &:focus {
       border-color: #000;
     }
   
     &:focus-within {
       box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
     }
   
     &::-ms-expand {
       display: none;
     }
   }
   
   .filter-item select option {
     padding: 8px;
     font-size: 16px;
     background-color: transparent;
     color: #333;
   }
   
   .filter-item select option:hover {
     background-color: #f5f5f5;
   }
   .flex-container {
     display: flex;
   
     
   }
   .all {
     flex: 1;
     background-color:black;
   
   /*  margin-top: 5PX;*/
     
   }
   #all {
    
     margin: 0;
     padding: 0;
   }
   .allu{
      background-color: black;
   }
   .header{
     background-color:black;
   }
   
   .gallery {
    
     display: grid;
     grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
     gap: 10px; 
     row-gap: 0; 
     justify-content: center;
     align-items: center;
     margin-right:10px;
     margin-left:10px;
     
   }
   
   .gallery-item {
     
     text-align: center;
     break-inside: avoid; /* Prevent breaks inside items to keep masonry effect */
   }
   
   
   .image-card {
     display: relative;
     position: relative;
   }
   
   .gallery-item img {
     max-width: 100%;
     height: auto;
     display: block;
     border-radius: 8px;
     margin-bottom: 10px; /* Add margin between items for better visual separation */
   }
   
   .view-button {
     background-color: black;
     color: white;
     border: none;
     padding: 10px 20px;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     cursor: pointer;
     transition: background-color 0.3s ease;
   }
   
   
   
   .color-box {
   
     color: white;
     text-align: center;
     padding: 20px 0;
     font-size: 24px;
     font-weight: bold;
     border-radius: 10px;
     margin-top: 8px;
     font-family: "Times New Roman", Times, serif;
   }
   .sticky-color-circles{
       position: sticky;
       top: 0;
      
       z-index: 1000;
   }
   
   .slogan {
     margin: 0;
   }
   /* Styles pour la bannière */
   .banner {
    position: relative;
    /* Add the following properties as needed */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-left: 10PX;
    margin-right: 10PX;
    margin-top: 10PX;
  }
   
   .banner img {
     width: 100%;
     height: auto;
     display: block;
      
   }
   .wardrobe-text {
     text-align: center;
     color: white;
     padding: 20px;
    
   }
   .gal{
    width: 100%; /* Set width to 100% for full width */
    max-width: 1130px; /* Set a maximum width if needed */
    margin: 0 auto; /* Center the element */
  
   }
   .wardrobe-text h2,
   .wardrobe-text p {
     opacity: 0;
     animation: fadeInUp 1s ease forwards;
   }
   .espace{
    background-color: #000;
   }
   .wardrobe-text h2 {
     font-size: 36px;
     margin-bottom: 10px;
   }
   
   .wardrobe-text p {
     font-size: 18px;
     margin: 0;
     margin-bottom: 10px;
   }
   
   /* Add fade-in animation keyframes */
   @keyframes fadeInUp {
     from {
       opacity: 0;
       transform: translateY(20px);
     }
     to {
       opacity: 1;
       transform: translateY(0);
     }
   }
    .logo {
       display: flex;
      align-items: center;
       color: var(--logo-color);
       font-weight: 500;
       font-size: 1.1rem;
       border-color: var(--second-background-color);
       border-width: 0 0px 1px 0;
       border-style: solid;
       background-color: var(--header-background-color);
       transition: all 0.3s ease;
       &:hover {
         background-color: var(--white-color);
         color: white;
       }
       svg {
        margin-right: 10px;
       }
     }
      .logo {
       display: flex;
       align-items: center;
       padding: 1.5rem 2rem;
   
       img {
         width: 280px; /* Adjust the width as needed */
         height: auto; /* Maintain aspect ratio */
        
       }}
   
   aside {
     width: 100%;
     z-index: 2;
     
     max-width: 350px;
     display: flex;
     position: sticky;
     top: 0;
      overflow-y: auto;  /* Enable vertical scrollbar when content overflows */
     height: 100vh;
     border-right: 1px solid var(--second-background-color);
   }
     .banner-loading {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.3);
     color: white;
     font-size: 1.5rem;
     font-weight: bold;
   }
   .divider {
    border-bottom: 1px solid white;
    width: 100%;
    margin-bottom: 10px;
  }
   .selected-color {
     border: 50px solid black; /* Add a black border for the selected color */
     opacity: 4; /* Remove transparency for the selected color */
   }
   
   button {
     background-color: #000; /* Button background color */
     color: #fff; /* Button text color */
     padding: 10px 20px; /* Padding around button text */
     border: none; /* Remove default button border */
     border-radius: 5px; /* Add a slight border radius for a rounded appearance */
     cursor: pointer; /* Add a pointer cursor on hover for better user interaction */
     font-size: 16px; /* Adjust font size as needed */
     transition: background-color 0.3s ease; /* Smooth transition for background color change */
     margin-bottom: 50px;
   
     /* Additional styles for hover effect */
     &:hover {
       background-color: #333; /* Darken the background color on hover */
     }
   }
  
  
   .search-container { margin-left: 250px;   } 
   .search-wrapper { width: 100%; max-width: 800px; margin: 0 auto;  }
    .search { display: flex; 
      align-items: center; 
      padding: 10px 15px;
       background-color: var(--header-background-color);
       background-color: #fff;
        border-radius: 20px; box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1); }
         .search input { flex: 1; border: none; background: none; margin-right: 10px; outline: none; width: 500px; } 
         .search i { font-size: 1.2rem; cursor: pointer; }
         .search:hover {  border: 2px solid black;  }
         
  .icon{
    display:none;
  }
         @media screen and (max-width: 900px) {
          .icon{
    display:inline;
  }
    .fil{
      display: none;
    }
    .gal {
      width: 100vw; /* Set width to 100% for smaller screens */
      max-width: none; /* Remove max-width for smaller screens */
    }
   

   img{
    margin-right:400px;
   }
 
 
         }
  </style>
  