var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sticky-color-circles"},[_c('div',{staticClass:"color-circles"},[_c('div',{staticClass:"vv"},_vm._l((_vm.colors),function(couleur,index){return _c('button',{key:index,staticClass:"color-button",style:({
        backgroundColor: couleur,
        width: '25px', /* Adjust the button size as needed */
        height: '35px', /* Adjust the button size as needed */
        border: '2px solid white', /* White border */
        boxShadow: '0 0 0 3px black inset', /* Gray border */
        margin:'3px'
      }),on:{"click":function($event){return _vm.navigateToColor(couleur)}}})}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }